.card-container {
  height: 400px;
  perspective: 800px;
  position: relative;
  margin: auto;
  transition: ease-in;
}

.card {
  height: 380px;
  position: absolute;
  transform-style: preserve-3d;
  transition: all 0.4s ease-in-out;
}

.card.revealed {
  transform: rotateY(180deg);
  transition: all 0.4s ease-in-out;
}

.card .front {
  height: 380px;
  width: 380px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  overflow: hidden;
  text-align: center;
  background-color: rgba(255, 251, 251, 1);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .back {
  height: 380px;
  width: 380px;
  text-align: center;
  background-color: rgba(255, 251, 251, 1);
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 0 20px;
}

.text {
  font-family: SansForgetica, sans-serif;
  color: #333;
  font-size: 4.5em;
}

.title {
  color: #333;
  font-size: 4em;
  width: 100%;
}

.subtitle{
  color: rgba(200, 10, 10, 1);
  font-size: 2.3em;
  padding-top: 10%;
  width: 100%;
}

@media (max-width: 410px) {
  .card-container {
    height: 90vw;
    width: 90vw;
    perspective: 180vw;
  }

  .card {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }

  .card .front, .card .back {
    width: 100%;
    height: 100%;
  }

  .text {
    font-size: 3.5em !important;
  }
}