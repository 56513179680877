.cardRow{
    margin-top: 10%;
    padding: 5px;
}

.btnRow{
    padding: 5px;
}

@media (max-width: 420px) {
  .App {
    bottom: 20px;
    position: absolute;
  }
}