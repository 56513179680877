body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #9c88ff;
}

@font-face {
  font-family: SansForgetica;
  src: url("assets/fonts/SansForgetica-Regular.otf") format("opentype");
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.rect-container {
  cursor: pointer;
  width: 400px;
}

.rect {
  width: 380px;
  border: 10px solid #8c7ae6;
  border-radius: 25px;
}

@media (max-width: 420px) {
  #root {
    height: 100%;
  }
}